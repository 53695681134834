<template>
  <v-card color="#FFFFFFB3" elevation="0">
    <v-card-text class="fill-height pa-5">
      <v-card
        class="d-flex flex-column"
        color="transparent"
        flat
        tile
        height="100%"
      >
        <v-card class="mb-auto" color="transparent" flat tile>
          <div class="text-h6 ems-blue-grey--text">上月總用電量</div>
          <div>
            <span class="text-h5 font-weight-bold accent--text">{{
              data.energy | checkNull
            }}</span>
            <span class="ml-2 text-h6 ems-blue-grey--text">kWh</span>
          </div>
        </v-card>
        <v-card class="mb-auto" color="transparent" flat tile>
          <div class="text-h6 ems-blue-grey--text">
            {{ isCityPage ? '前兩個月用電比' : '相較去年同期的用電比' }}
          </div>
          <div>
            <span
              class="text-h5 font-weight-bold"
              :class="{
                'secondary--text': data.yoy >= 0,
                'red--text': data.yoy < 0
              }"
              >{{ (data.yoy * 100).toFixed() }}%</span
            >
            <span
              v-if="!isCityPage"
              class="text-h5 font-weight-bold ml-10"
              :class="{
                'accent--text': data.yoy >= 0,
                'red--text': data.yoy < 0
              }"
              >{{ data.saving | checkNull }}</span
            >
            <span v-if="!isCityPage" class="ml-2 text-h6 ems-blue-grey--text"
              >kWh</span
            >
            <v-progress-linear
              :value="100 - parseInt(data.yoy * 100)"
              :buffer-value="100"
              height="7"
              :color="data.yoy >= 0 ? `ems-aquamarine` : `red`"
            ></v-progress-linear>
          </div>
        </v-card>
        <v-card color="transparent" flat tile>
          <div class="text-h6 ems-blue-grey--text">上月太陽能發電量</div>
          <div>
            <span class="text-h5 font-weight-bold accent--text">{{
              data.solar | checkNull
            }}</span>
            <span class="ml-2 text-h6 ems-blue-grey--text">kWh</span>
          </div>
        </v-card>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isCityPage: this.$route.name === 'CityOverview'
    }
  },
  filters: {
    checkNull(value) {
      return String(value) === 'null' ? '--' : parseInt(value)
    }
  }
}
</script>

<style></style>
