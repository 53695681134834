<template>
  <div class="city-overview pa-3">
    <v-row>
      <v-col cols="12" sm="7">
        <weatherCard :cityId="tagId" class="header-card" />
      </v-col>
      <v-col cols="12" sm="5">
        <energyCard :data="eneryData" class="header-card" />
      </v-col>
    </v-row>
    <v-row align="end">
      <v-col cols="12" sm="7">
        <span class="font-weight-bold title accent--text">能源地圖</span>
      </v-col>
      <v-col cols="6" sm="3">
        <span class="font-weight-bold title accent--text" v-if="pageData"
          >{{ pageData.displayName }}節電排名</span
        >
      </v-col>
      <v-col cols="6" sm="2"> </v-col>
      <v-col cols="12" class="pt-0">
        <v-card elevation="0" color="#FFFFFF99">
          <v-card-text>
            <v-row>
              <v-col>
                <v-row align="center">
                  <v-col cols="12" md="auto">
                    <TopRankInfo v-model="theDistData" :isDistRank="true" />
                  </v-col>
                  <v-col>
                    <div class="text-center">
                      <gMap
                        :ranking="distRankingData"
                        :mapCenter="
                          distRankingData[0]
                            ? {
                                lat: distRankingData[0].latitude,
                                lng: distRankingData[0].longitude
                              }
                            : {
                                lat: 0,
                                lng: 0
                              }
                        "
                        @input="clickMapMarker"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5" style="border-left: 1px solid #dddddd88">
                <PowerSavingRanking :ranking="distRankingData" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import weatherCard from './weatherCard'
import energyCard from '@/components/energyCard'
import PowerSavingRanking from '@/components/PowerSavingRanking'
import gMap from '@/components/gMap'
import TopRankInfo from '@/components/TopRankInfo'
import Competition from '@/api/ems/Competition'
import Meter from '@/api/ems/Meter'

export default {
  name: undefined,
  mixins: [],
  components: {
    weatherCard,
    energyCard,
    PowerSavingRanking,
    gMap,
    TopRankInfo
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      distRankingData: [],
      selectDistData: null,
      eneryData: {},
      theDistData: {}
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData'
    }),
    firstDistData() {
      const firstDist = this.distRankingData.find((dist) => dist.ranking === 1)
      return firstDist || {}
    }
  },
  watch: {
    tagId() {
      this.init()
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.getDistRanking()
      this.getEnery()
    },
    async getDistRanking() {
      try {
        const { data } = await Competition.dist(this.tagId)
        this.distRankingData = data
        const firstDist = this.distRankingData.find(
          (dist) => dist.ranking === 1
        )
        this.theDistData = firstDist || {}
      } catch (error) {
        console.error(error)
      }
    },
    getEnery() {
      Meter.city(this.tagId).then((response) => {
        if (!response.data) {
          return
        }
        this.eneryData = response.data
      })
    },
    clickMapMarker(dist) {
      this.theDistData = dist
    }
  }
}
</script>

<style lang="scss" scoped>
.city-overview::v-deep {
  .header-card.v-card {
    height: 360px;
  }
}
</style>
