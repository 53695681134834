<template>
  <div class="dist-ranking">
    <v-list class="sort-list transparent">
      <v-list-item class="item-header" dense>
        <v-list-item-icon style="align-items: center; align-self: center">
          <v-list-item-subtitle>名次</v-list-item-subtitle>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>{{
            isCityPage ? '區域' : '學校'
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-subtitle>節電量(kWh)</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-subtitle>上月用電量</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div class="item-body">
        <div
          v-if="!sortedRanking.length"
          class="text-center ems-blue-grey--text mt-5"
        >
          ( 無資料 )
        </div>
        <v-list-item
          v-for="(item, i) in sortedRanking"
          :key="i"
          :class="`item-sort-${item.ranking}`"
        >
          <v-list-item-icon>
            <v-avatar size="1.4rem">
              <span class="white--text">{{ item.ranking }}</span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
              v-text="item.name"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-text="item.saving"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-text="item.energy"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    ranking: {
      type: Array
    }
  },
  data: function () {
    return {
      isCityPage: this.$route.name === 'CityOverview'
    }
  },
  computed: {
    sortedRanking() {
      return this.ranking.sort((a, b) => a.ranking - b.ranking)
    }
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.sort-list::v-deep {
  .item-header {
    color: #ddd;
  }
  .item-body {
    max-height: 330px;
    overflow-y: auto;
  }
  .v-list-item:not(.item-header) {
    border: solid 1px #ddd;
    margin: 5px;
    border-radius: 5px;
    .v-list-item__title {
      color: var(--v-accent-base);
    }
  }
  .item-sort-1 {
    background: linear-gradient(65deg, #2fd9ff 0%, #00ddc9 50%);
    .v-avatar {
      background-color: var(--v-warning-lighten2) !important;
      border-color: var(--v-warning-lighten2) !important;
    }
  }
  .item-sort-2,
  .item-sort-3 {
    .v-avatar {
      background-color: var(--v-primary-base) !important;
      border-color: var(--v-primary-base) !important;
    }
  }

  .v-avatar {
    background-color: var(--v-ems-pale-grey-darken3) !important;
    border-color: var(--v-ems-pale-grey-darken3) !important;
  }
}
</style>
